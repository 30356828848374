import '../styles/normalize.css';
import '../styles/fonts.css';

// Fluid typography settings
const minViewportWidth = 478;
const maxViewportWidth = 2560;
const minFontSize = 20;
const maxFontSize = 32;

// Styles
export default {
	breakpoints: ['991px'],

	colors: {
		// Semantic styles
		text: (t) => t.colors.grey_10,
		background: (t) => t.colors.grey_90,
		primary: (t) => t.colors.orange_50,
		secondary: (t) => t.colors.orange_20,
		accent: (t) => t.colors.yellow_50,
		highlight: (t) => t.colors.yellow_70,
		muted: (t) => t.colors.grey_80,
		// Palette
		grey_10: '#1F150B',
		grey_50: '#D1D0CD',
		grey_80: '#F0EFEB',
		grey_90: '#F7F7F5',
		orange_20: '#84623A',
		orange_50: '#FF6933',
		yellow_50: '#FFF170',
		yellow_70: '#FFF7AD',
	},

	sizes: {
		xs: '0.1667rem',
		s: '0.3333rem',
		m: '0.6667rem',
		l: '1.3333rem',
		xl: '2.6667rem',
		xxl: '5.3333rem',
		xxxl: '10.6667rem',
	},

	space: {
		none: '0px',
		xs: '0.1667rem',
		s: '0.3333rem',
		m: '0.6667rem',
		l: '1.3333rem',
		xl: '2.6667rem',
		xxl: '5.3333rem',
		xxxl: '10.6667rem',
	},

	fonts: {
		heading: 'Signifier, serif',
		body: '"Ideal Sans", san-serif',
	},

	fontWeights: {
		light: 300,
		regular: 400,
		medium: 500,
		semibold: 600,
		bold: 700,
	},

	fontSizes: [
		'0.5833rem', // 14px
		'0.6667rem', // 16px
		'1rem', // 24px
		'1.3333rem', // 32px
		'2rem', // 48px
		'2.6667rem', // 64px
		'3.3333rem', // 80px
	],

	lineHeights: {
		heading_l: '1.1',
		heading_m: '1.2',
		body: '1.4',
	},

	text: {
		h1: {
			fontFamily: 'heading',
			fontWeight: 'light',
			fontSize: 6,
			lineHeight: 'heading_l',
			margin: 0,
			textUnderlineOffset: '0.1875em',
		},

		h2: {
			fontFamily: 'heading',
			fontWeight: 'light',
			fontSize: 5,
			lineHeight: 'heading_l',
			margin: 0,
			textUnderlineOffset: '0.1875em',
		},

		h3: {
			fontFamily: 'heading',
			fontWeight: 'light',
			fontSize: 4,
			lineHeight: 'heading_m',
			margin: 0,
			textUnderlineOffset: '0.1875em',
		},

		h4: {
			fontFamily: 'heading',
			fontWeight: 'light',
			fontSize: 3,
			lineHeight: 'heading_m',
			textUnderlineOffset: '0.1251875emem',
		},

		h5: {
			fontFamily: 'body',
			fontWeight: 'medium',
			fontSize: 2,
			lineHeight: 'heading_m',
			textUnderlineOffset: '0.1875em',
		},

		h6: {
			fontFamily: 'body',
			fontWeight: 'semibold',
			fontSize: 1,
			lineHeight: 'heading_m',
			textTransform: 'uppercase',
			letterSpacing: '0.08em',
			textUnderlineOffset: '0.5em',
		},

		bodyLarge: {
			fontFamily: 'body',
			fontWeight: 'light',
			fontSize: '1rem',
			lineHeight: 'body',
			margin: '1.5rem 0',
			textUnderlineOffset: '0.1875em',
			a: {
				variant: 'text.link',
			},
			i: {
				variant: 'text.italic',
			},
			strong: {
				variant: 'text.bold',
			},
		},

		bodyMedium: {
			fontFamily: 'signifier',
			fontWeight: 'light',
			fontSize: [16, 18],
			lineHeight: '24px',
			textUnderlineOffset: '0.1875em',
			a: {
				variant: 'text.link',
			},
			i: {
				variant: 'text.italic',
			},
			strong: {
				variant: 'text.bold',
			},
		},

		caption: {
			fontSize: [14, 16],
			fontFamily: 'signifier',
			fontWeight: 'light',
			lineHeight: 'body',
			width: '20vw',
			minWidth: '300px',
			maxWidth: '360px',
		},

		link: {
			cursor: 'pointer',
			color: 'inherit',
			textDecorationColor: 'grey_50',
			textDecorationThickness: 'clamp(2px, 0.0625em, 3px)',
			textUnderlineOffset: 'inherit',
			transition: '0.2s ease-out',
			'&:hover': {
				color: 'primary',
				textDecorationColor: 'primary',
			},
		},

		italic: {
			fontStyle: 'italic',
		},

		bold: {
			fontWeight: 'semibold',
		},
	},

	styles: {
		root: {
			// Fluid typography
			fontSize: `calc(${
				minFontSize + 'px'
			} + (${maxFontSize} - ${minFontSize}) * ((100vw - ${
				minViewportWidth + 'px'
			}) / (${maxViewportWidth} - ${minViewportWidth})))`,

			'*::selection': {
				bg: '#FFF7AD',
			},
		},

		h1: {
			variant: 'text.h1',
		},

		h2: {
			variant: 'text.h2',
		},

		h3: {
			variant: 'text.h3',
		},

		h4: {
			variant: 'text.h4',
		},

		h5: {
			variant: 'text.h5',
		},

		h6: {
			variant: 'text.h6',
		},
	},
};
